import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
//Pipes
import { FiltroPipe, CapitalizaPipe, FiltrarAjustadoresPipe, TiempoTranscurridoPipe, SortPipe, DescripcionSelectPipe, MaskCardNumberPipe, ExtractDataPipe, FiltroBusquedaPipe, FiltrarMesesPipe, ValidarTipoNumeroPipe, DescripcionAnySelectPipe, DescripcionSelectUppercasePipe, LongitudTxtPipe  } from './pipes';
//Directives
import { DisableControlDirective, OnlyNumber, UpperCaseTextDirective, TrueFalseDirective, TipoFecha, HorasMinutosDirective, MinimoMaximoInputDirective, NumberFormatterDirective, FormatoNumeroMonedaDirective, MaxLengthInputDirective, OnlyCurrencyFormatDirective, OnlyPercentFormatDirective, RefreshComponentDirective} from './directives';
import { CveMonedaPipe } from './pipes/cve-moneda.pipe';
import { FiltroTipoPersonaPipe } from './pipes/filtro-tipo-persona.pipe';
import { ExcluirCampoPipe } from './pipes/excluir-campo.pipe';
import { CommaSeparatorPipe } from './pipes/comma-separator.pipe';
import { DescripcionSelectLimitPipe } from './pipes/descripcion-select-limit.pipe';
import { FiltrarListaUdiPipe } from './pipes/filtrar-lista-udi.pipe';
import { ValorFacturaMaxMinDirective } from './directives/valor-factura-max-min.directive';
import { UdiPercentDirective } from './directives/udi-percent.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomTooltipDirective } from './directives/tooltip/custom-tooltip.directive';
import { CustomTooltipComponent } from './directives/tooltip/custom-tooltip.component';
import { ActivarDesactivarCatalogoDirective } from './directives/activar-desactivar-catalogo.directive';
import { OrdenarUdisPipe } from './pipes/ordenar-udis.pipe';
import { TipoPersonaPipe } from './pipes/filtra-tipo-persona.pipe';
import { FormatearFechaPipe } from './pipes/formatear-fecha.pipe';
import { ValidarTimeAmPmPipe } from './pipes/validar-time-am-pm.pipe';
import { FiltroListaDatosTecnicosPipe } from './pipes/filtro-lista-datos-tecnicos.pipe';
import { OnlyTextDirective } from './directives/only-text.directive';
import { MapArrayStringPipe } from './pipes/mapToArrayString.pipe';

const PIPES = [
  CapitalizaPipe,
  FiltrarAjustadoresPipe,
  TiempoTranscurridoPipe,
  SortPipe,
  FiltroPipe,
  DescripcionSelectPipe,
  MaskCardNumberPipe,
  ExtractDataPipe,
  CveMonedaPipe,
  FiltroTipoPersonaPipe,
  ExcluirCampoPipe,
  CommaSeparatorPipe,
  DescripcionSelectLimitPipe,
  FiltrarListaUdiPipe,
  FiltroBusquedaPipe,
  FiltrarMesesPipe,
  OrdenarUdisPipe,
  ValidarTipoNumeroPipe,
  TipoPersonaPipe,
  FormatearFechaPipe,
  DescripcionAnySelectPipe,
  ValidarTimeAmPmPipe,
  DescripcionSelectUppercasePipe,
  FiltroListaDatosTecnicosPipe,
  LongitudTxtPipe,
  MapArrayStringPipe
];

const DIRECTIVES = [
  DisableControlDirective,
  OnlyNumber,
  UpperCaseTextDirective,
  TrueFalseDirective,
  TipoFecha,
  HorasMinutosDirective,
  MinimoMaximoInputDirective,
  ValorFacturaMaxMinDirective,
  UdiPercentDirective,
  CustomTooltipDirective,
  ActivarDesactivarCatalogoDirective,
  NumberFormatterDirective,
  OnlyTextDirective,
  FormatoNumeroMonedaDirective,
  OnlyCurrencyFormatDirective,
  OnlyPercentFormatDirective,
  RefreshComponentDirective,
  MaxLengthInputDirective,
];

@NgModule({
  declarations: [...PIPES, ...DIRECTIVES, CveMonedaPipe, ExcluirCampoPipe, CommaSeparatorPipe, CustomTooltipComponent],
  imports: [CommonModule, ReactiveFormsModule],
  exports: [...PIPES, ...DIRECTIVES],
  providers: [MaskCardNumberPipe,CurrencyPipe, DecimalPipe]
})

export class SharedModule { }
