import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[OnlyPercentFormat]'
})
export class OnlyPercentFormatDirective {

    constructor(private el: ElementRef) { }

    @HostListener('focus', ['$event'])
    onFocus(event: Event) {      
      const input = event.target as HTMLInputElement;
      input.value = input.value.replace(/\%/g, '').replace(/,/g, '');
      setTimeout(() => {
        input.setSelectionRange(0, 0);
      }, 50);
    }
  }

