import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../../../services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ModalIngresarComponent } from '../modal-ingresar/modal-ingresar.component';
import { fromEvent, throwError } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { ModalConsultaRfcComponent } from '../modal-consulta-rfc/modal-consulta-rfc.component';
import Swal from 'sweetalert2';
import { INormaModelInput, IResponseModel } from 'src/app/models/seguros-atlas/atlas.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  imagen: string;
  loading = false;
  show = true;
  show2 = false;
  Ventana = true;
  Asegurado = false;
  Agente = false;
  Proveeedor = false;
  Promotor = false;
  Reasegurador = false;
  Empleado = false;
  Ejecutivo = false;

  credentials = {
    Usuario: '',
    Password: '',
    RememberMe: false
  };

  msg = fromEvent<MessageEvent>(window, "message");

  constructor(private authService: AuthService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,
              private router: Router) {

               }

  ngOnInit() {
    sessionStorage.removeItem('AP-IN');
    this.obtenerDatosCotizador();

    if (localStorage.getItem('AP-TK')) { this.router.navigate(['primeraPagina']); }
    if (sessionStorage.getItem('adios')) {
      const msgError = 'Se ha iniciado sesión en otro dispositivo o tus credenciales han expirado, vuelve a ingresar';
      setTimeout(() => {
        this.abrirSnackBar(msgError, `Autentificación`, 'errorSnackbar');
        sessionStorage.removeItem('adios');
      }, 200);
    }

    this.imagenAleatoria();
  }


  // Recibe token por 'postMessage'
  obtenerDatosCotizador() {
    this.msg.subscribe(event => {

      if (event.data.aptk && event.data.apdp  && event.data.aprtk  && event.data.port) {
        localStorage.setItem('AP-TK', event.data.aptk.value);
        localStorage.setItem('AP-DP', event.data.apdp.value);
        localStorage.setItem('AP-RTK', event.data.aprtk.value);
        localStorage.setItem('DATA-PORT', event.data.port.value);
        this.router.navigate(['/']);
      }
    });
  }

  modificarUsuario() {
    this.credentials.Password ? this.credentials.Password = '' : null;
  }

  private abrirSnackBar(mensaje: string, titulo: string, clase: string) {
    const config: MatSnackBarConfig = {
      duration: 5000,
      panelClass: clase,
    };
    this.snackBar.open(mensaje, titulo, config);
  }

  imagenAleatoria() {
    this.imagen = `assets/img/${Math.floor((Math.random() * Math.floor(5)) + 1)}.jpg`;
    setTimeout(() => {
      this.imagenAleatoria();
    }, 30000);
  }

  abrirModal(){
    this.loading = true;

    this.authService.validaSesion(this.credentials.Usuario).subscribe(
      (res) => {
        this.authService.obtenerAlias(this.credentials.Usuario).subscribe(
          (data) => {

            const norma: INormaModelInput = {
              NumNorma: 756,
              Parametro1: 0,
              Parametro2: 0,
              Parametro3: 0,
            };

            this.authService.obtenerNormaAtlas(norma).subscribe(
              (res: IResponseModel) => {
                if (res.Respuesta.Status == 1) {
                  Swal.fire({
                    title: `¡Informativo!`,
                    icon: 'info',
                    html: `Estimados usuarios, <br/>
                            Les informamos que hemos actualizado los envíos de correo de nuestro servicio 
                            Cero Papel. Si recibiste un correo con el asunto <b>"Notificación Paperless"</b>, por favor, ignóralo. 
                            A partir de ahora, los correos de Cero Papel se enviarán con el asunto 
                            <b>"Notificación Seguros Atlas CeroPapel"</b>. <br/>
                            Agradecemos su atención y comprensión.
                          `,
                    confirmButtonText: 'Aceptar',
                    allowOutsideClick: false,
                  });
                }
              },
              (error) => {
                Swal.fire({
                  title: 'Aviso',
                  text: 'Ups ocurrió algo mientras consultabamos los datos',
                  icon: 'error',
                });
              }
            );
                    
            const configModal: MatDialogConfig<any> = {
              data: { alias: data, login: this.credentials }
            };
            const dialogRef = this.dialog.open(ModalIngresarComponent, configModal).afterClosed().subscribe(() => {
              this.loading = false;
            });
          },
          (error) => {
            this.loading = false;
            this.abrirSnackBar('Error al obtener el alias', 'Autenticación', 'errorSnackbar');
          }
        );
      },
      (error) => {
        this.loading = false;
        Swal.fire({ title: `¡Atención!`, icon: 'error', text: error?.error[0]?.Exception, confirmButtonText: 'Aceptar'});

        if (error?.error[0]?.Key == "Usuario") 
          return this.abrirSnackBar(`Ya existe una sesión abierta con el usuario ${this.credentials.Usuario}`, `cerrar`, 'errorSnackbar');
  
        this.abrirSnackBar('Verifique su nombre de usuario', `Autentificación`, 'errorSnackbar');
      }
    );
  }

  Display() {
    this.Ventana=false;
  }

  irRegistroProspectoProveedor() {
    this.router.navigate(['/proveedores/mesa-control/siniestros/prospectos-proveedores']);
  }

  irActualizarFacturacion() {
    this.router.navigate(['/facturacion/actualizacion-csf']);
  }

  irAConsultarDatosActualizados() {
    const dialogRef = this.dialog.open(ModalConsultaRfcComponent, {
      // height: '90%',
      width: '400px'
    })
  }

  verAsegurado(x) {

    if ( x === 'A') {
      this.show = false
      this.show2 = true;
      this.Asegurado = true; //Si x es === a 'A' abre la  ventana de Asegurado
    } else if ( x === 'Ag') {
      this.show = false
      this.show2 = true;
      this.Agente = true; //si x es === a 'Ag' abre la ventana de Agente
    } else if (x === 'Proveedor') {
      this.show = false
      this.show2 = true;
      this.Proveeedor = true;
    }else if (x === 'Promotor') {
      this.show = false
      this.show2 = true;
      this.Promotor = true;
    }else if (x === 'Reasegurador') {
      this.show = false
      this.show2 = true;
      this.Reasegurador = true;
    }
    else if (x === 'Empleado') {
      this.show = false
      this.show2 = true;
      this.Empleado = true;
    }else if (x === 'Ejecutivo') {
      this.show = false
      this.show2 = true;
      this.Ejecutivo = true;
    }
  }
  regresar(){
    this.Ventana = true;
    this.show = true;
    this.show2 = false;
    this.Agente = false;
    this.Asegurado = false;
    this.Proveeedor = false;
    this.Promotor = false;
    this.Reasegurador = false;
    this.Empleado = false;
    this.Ejecutivo = false;
  }

}
