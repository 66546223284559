<div class="tabledit-table">
    <div class="table-container">
        <table #tablaMat mat-table [dataSource]="dataSourceMaster" [multiTemplateDataRows]="extraColumns.length > 0 ? true : false">
            <ng-container *ngFor="let column of displayedColumnsMaster; let col = index" [matColumnDef]="column.columnDef">
                <th mat-header-cell *matHeaderCellDef [ngStyle]="getColumnStyles(column)">
                    <ng-container *ngIf="column.type === 'checkbox'">
                        <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()" [tabIndex]="-1">
                        </mat-checkbox>
                    </ng-container>
                    <ng-container *ngIf="column.type !== 'checkbox'">
                        {{ column.header }}
                    </ng-container>
                </th>
                <td mat-cell *matCellDef="let element; let cell = index"
                    class="{{column.attr === 'rowspan' ? 'text-center' : 'text-left'}}"
                    [ngClass]="{'disabled': isDisableRows(element)}" [ngStyle]="getCellStyles(column)"
                    [attr.rowspan]="column.attr === 'rowspan' ? getRowSpan(column.columnDef, cell) : null"
                    [style.display]="column.attr === 'rowspan' ? getRowSpan(column.columnDef, cell) ? '' : 'none' : null">
                    <ng-container *ngIf="column.type === 'checkbox-cobertura'">
                        <mat-checkbox color="primary" (change)="toggleCheckbox($event, element); chkRowSelectSumaAsegurada($event, element);" [checked]="!!element.Seleccionada"
                            [aria-label]="checkboxLabel(element)"
                            (click)="$event.stopPropagation();" [tabIndex]="-1">
                        </mat-checkbox>
                    </ng-container>
                    <ng-container *ngIf="column.type === 'checkbox'">
                        <mat-checkbox color="primary" (change)="toggleCheckbox($event, element); chkRowSelect(element);" [checked]="!!element.Seleccionada"
                            [aria-label]="checkboxLabel(element)"
                            (click)="$event.stopPropagation();" [tabIndex]="-1">
                        </mat-checkbox>
                    </ng-container>
                    <ng-container *ngIf="column.type === 'label'">
                        {{ element[column.columnDef] }}
                    </ng-container>
                    <ng-container *ngIf="column.type === 'text'">
                        <input matInput type="text" class="input-tabla-dinamica" placeholder="{{ column.columnDef }}"
                            [value]="element[column.columnDef]" [tabIndex]="-1">
                    </ng-container>
                    <ng-container *ngIf="column.type === 'number-cobertura'">
                        <ng-container *ngIf="isNumber(element[column.columnDef]) else elseNumber">
                            <ng-container *ngIf="element.Enabled === true else elseEnabled">
                                <input matInput type="text" class="input-tabla-dinamica" placeholder="{{ column.columnDef }}" 
                                [disabled]="disabled"                          
                                [value]="element[column.columnDef] | currency" [OnlyNumber]="true"
                                [readonly]="element?.Seleccionada === undefined ? false : !element.Seleccionada"
                                OnlyCurrencyFormat (blur)="txtValueSumaAsegurada($event, element)" [tabIndex]="-1">
                            </ng-container>
                            <ng-template #elseEnabled>
                                {{ element[column.columnDef] | currency}}
                            </ng-template>
                        </ng-container>
                        <ng-template #elseNumber>
                            {{ element[column.columnDef] }}
                        </ng-template>
                    </ng-container>
                    <ng-container *ngIf="column.type === 'number-ahorro'">
                        <input matInput type="text" class="input-tabla-dinamica" placeholder="{{ column.columnDef }}"
                            [value]="element[column.columnDef] | currency" [OnlyNumber]="true"
                            (change)="updateValue($event.target.value, element, column); calculateColumnSum(column.columnDef);"
                            OnlyCurrencyFormat (blur)="txtTextElement(element)" [tabIndex]="-1">
                    </ng-container>
                    <ng-container *ngIf="column.type === 'number'">
                        <input matInput type="text" class="input-tabla-dinamica" placeholder="{{ column.columnDef }}"                            
                            [value]="element[column.columnDef]" [OnlyNumber]="true"
                            [(ngModel)]="element[column.columnDef]"
                            (ngModelChange)="calculateColumnSum(column.columnDef)" OnlyCurrencyFormat
                            (blur)="txtTextElement(element)" [tabIndex]="-1">
                    </ng-container>
                    <ng-container *ngIf="column.type === 'money'">
                        {{ (element[column.columnDef] || 0) | currency }}
                    </ng-container>
                    <ng-container *ngIf="column.type === 'percent'">
                        <input matInput type="text" class="input-tabla-dinamica"
                            placeholder="{{ element[column.startrange] }} - {{ element[column.endrange] }} %"
                            [value]="element[column.columnDef]" [OnlyNumber]="true"
                            [(ngModel)]="element[column.columnDef]"
                            (blur)="txtAssignRange(element, column.columnDef, $event, element[column.startrange], element[column.endrange])" 
                            OnlyPercentFormat [tabIndex]="0">
                    </ng-container>
                    <ng-container *ngIf="column.type === 'percentage'">
                        {{ (element[column.columnDef] || 0) }}%
                    </ng-container>
                    <ng-container *ngIf="column.type=== 'icon'">
                        <ng-container *ngIf="column.name === 'add_box'">
                            <mat-icon color="primary" (click)="btnRowspanIcon(column)">{{column.name}}</mat-icon>
                        </ng-container>
                        <ng-container *ngIf="column.name === 'delete_forever'">
                            <mat-icon color="primary" (click)="btnDeleteRow(element)">{{column.name}}</mat-icon>
                        </ng-container>
                    </ng-container>
                </td>
            </ng-container>

            <!-- Extra Columns -->
            <ng-container *ngFor="let extra of extraColumns">
                <ng-container *ngFor="let col of extra" [matColumnDef]="col.key">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="col.colspan">
                        <p>{{col.key}}&nbsp;&nbsp;
                            <mat-radio-group aria-label="Select an option"
                                (change)="toggleRadioSelection($event.value)">
                                <mat-radio-button [value]="true" [checked]="col.value === true ? true : false">Si</mat-radio-button>
                                <mat-radio-button [value]="false" [checked]="col.value === false ? true : false">No</mat-radio-button>
                            </mat-radio-group>
                        </p>
                    </td>
                </ng-container>
            </ng-container>
            
            <!-- Empty column -->
            <ng-container matColumnDef="empty">
                <th mat-header-cell *matHeaderCellDef colspan="2"></th>
            </ng-container>
            <!-- Extra header column -->
            <ng-container matColumnDef="extraHeader1">
                <th mat-header-cell *matHeaderCellDef colspan="2"> Prima objetivo </th>
            </ng-container>
            
            <!-- Extra header column -->
            <ng-container matColumnDef="extraHeader2">
                <th mat-header-cell *matHeaderCellDef colspan="2"> Prima planeada </th>
            </ng-container>
            
            <!-- Extra header column -->
            <ng-container matColumnDef="extraHeader3">
                <th mat-header-cell *matHeaderCellDef colspan="2"> Prima Adicional </th>
            </ng-container>

            <ng-container *ngIf="showExtraHeaders === true;">
                <tr mat-header-row *matHeaderRowDef="['empty', 'extraHeader1', 'extraHeader2', 'extraHeader3'];"></tr>
            </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="getColumnDefs()"></tr>
            <tr mat-row *matRowDef="let row; columns: getColumnDefs();"></tr>
            <!-- Extra Rows -->
            <ng-container *ngFor="let extra of extraColumns;">
                <tr mat-row *matRowDef="let row; columns: extra | mapToArrayString: 'key'; when: isExtendedRow"></tr>
            </ng-container>
        </table>
    </div>
    <mat-paginator *ngIf="this.tableMaster?.paginator"
        [pageSizeOptions]="[this.tableMaster?.paginator]"></mat-paginator>
</div>
<div class="tabledit-table">
    <div class="table-container">
        <table mat-table [dataSource]="dataSourceFooter">
            <ng-container *ngFor="let column of displayedColumnsFooter" [matColumnDef]="column.columnDef">
                <th mat-header-cell *matHeaderCellDef class="text-white" [ngStyle]="getColumnStyles(column)">
                    {{column.header}} </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container [ngSwitch]="getColumnTypeFooter(column)">
                        <input *ngSwitchCase="'text'" [value]="element[column.columnDef]" class="text-input" />
                        <img *ngSwitchCase="'img'" [src]="element[column.columnDef]" />
                        <input *ngSwitchCase="'number'" matInput type="text"
                            placeholder="{{ column.columnDef }}" readonly="true" [value]="(element[column.columnDef] || 0) | currency"
                            [OnlyNumber]="true">
                        <span *ngSwitchCase="'percent'">
                            <label>{{ (element[column.columnDef] || 0) }}%</label>
                        </span>
                        <span *ngSwitchCase="'money'">
                            <label>{{ (element[column.columnDef] || 0) | currency }}</label>
                        </span>
                        <span *ngSwitchDefault>
                            <!-- <div class="text-input-footer">{{element[column.columnDef]}} &nbsp;</div> -->
                            {{element[column.columnDef]}}
                        </span>
                    </ng-container>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="getColumnDefsFooter()" class="header-hidden"></tr>
            <tr mat-row *matRowDef="let row; columns: getColumnDefsFooter();"></tr>
        </table>
    </div>
</div>
