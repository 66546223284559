import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { ActivarLoadingAction, DesactivarLoadingAction } from 'src/app/@store/actions';
import { AppState } from 'src/app/@store/app.reducers';
import { ITableconfig } from 'src/app/models/vida-individual/vida-universal-cotizacion.models';
import Swal from 'sweetalert2';

// public extraColumns: any[] = [
//   [
//     {
//       key: 'otherDescription',
//       colspan: 5,
//       render: (): string => `Radio`
//     }
//   ]
// ];

@Component({
  selector: 'app-table-dinamica',
  templateUrl: './table-dinamica.component.html',
  styleUrls: ['./table-dinamica.component.scss']
})
export class TableDinamicaComponent implements OnInit, OnChanges, AfterViewInit {
//@ViewChild('tablaMat', { read: ElementRef }) tablaMat!: ElementRef;
//Entradas: Configuracion de las tablas
@Input() tableMaster?: ITableconfig;
@Input() tableFooter?: ITableconfig;
@Input() isMetaAhorro?: boolean;
@Input() showExtraHeaders: boolean = false;
@ViewChild(MatPaginator) paginator!: MatPaginator;
@ViewChildren('txtTotalPercent') txtTotalPercentInputs?: QueryList<ElementRef>;

//SalidasEventos: Reservas
@Output() onBtnRowspanClick = new EventEmitter<any>();
@Output() onTxtAssignRangeBlur = new EventEmitter<any>();
//SalidasEventos: Coberturas
@Output() onTtxtValueSumaAseguradaBlur = new EventEmitter<any>();
@Output() onToggleRadioSelection = new EventEmitter<any>();
//SalidasEventos: Generico
@Output() onChkSelectClick = new EventEmitter<any>();
@Output() onBtnDeleteRowClick = new EventEmitter<any>();
@Output() onTxtTextBlur = new EventEmitter<any>();

public tableId: string = '';
public displayedColumnsMaster: Array<any> = [];
public dataSourceMaster!: MatTableDataSource<any>;
public selection = new SelectionModel<any>(true, []);
public extraColumns: Array<Array<any>> = [];

public spanColumns: Array<any> = [];
public spanningColumns: Array<any> = [];

public displayedColumnsFooter: Array<any> = [];
public dataSourceFooter!: MatTableDataSource<any>;

public applyDisabledStyle : boolean = false;

public matRadioGroupState : boolean;

public rowspanAppliedByColumn: { [key: string]: boolean } = {};
  constructor(
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.tableId = 'mat-table-' + Date.now();
    this.displayedColumnsMaster = this.tableMaster?.columns;
    this.dataSourceMaster = new MatTableDataSource<any>(this.tableMaster?.dataSource);
    this.spanningColumns = this.tableMaster?.columns.filter((column: { attr: string; }) => column.attr === 'rowspan').map((column: { columnDef: any; }) => column.columnDef);
    this.extraColumns = this.tableMaster?.extraColumns ? this.tableMaster?.extraColumns.map((subArray: any[]) =>
      subArray.map(column => ({
        key: column.Key,
        colspan: column.Colspan,
        render: column.Render,
        atIndex: column.AtIndex,
        value: column.RadioButtonValue      
      }))
    ) : [];

    this.tableMaster?.extraColumns !== undefined ? this.tableMaster?.extraColumns : [];
    
    if (this.spanningColumns !== undefined && this.spanningColumns.length > 0) {
      this.spanningColumns.forEach(columnDef => {
        this.cacheSpan(columnDef, (d: { [x: string]: any; }) => d[columnDef]);
      });
    }

    this.dataSourceMaster.data.forEach((row: any) => {
      if (row.hasOwnProperty('Seleccionada') && row.Seleccionada) {
        this.selection.select(row);
      }
    });

    if (this.tableMaster?.paginator !== undefined) {
      this.dataSourceMaster.paginator = this.paginator;
    }

    this.displayedColumnsFooter = this.tableFooter?.columns;
    this.dataSourceFooter = new MatTableDataSource<any>(this.tableFooter?.dataSource); 

    this.calculateColumnSum("PrimaPlaneada");
    this.calculateColumnSum("PrimaAdicional");
  }

  ngAfterViewInit(): void {
    if (this.tableMaster?.paginator !== undefined) {
      this.dataSourceMaster.paginator = this.paginator;
    }
    // if (this.tablaMat) {
    //   this.addTableTabListener();
    // }
  }

  // private addTableTabListener() {
  //   const tablaElement = this.tablaMat.nativeElement;

  //   if (tablaElement) {
  //     tablaElement.addEventListener('keydown', (event: KeyboardEvent) => {
  //       if (event.key === 'Tab' || event.key === ' ') {
  //         event.preventDefault();
  //         this.handleTabKey(event);
  //       }
  //     });
  //   }
  // }

  // private handleTabKey(event: KeyboardEvent) {
  //   // Encuentra el elemento enfocado actualmente dentro de la tabla
  //   const focusedElement = document.activeElement;

  //   // Verifica si el elemento enfocado es un checkbox, input o select
  //   const isCheckbox = focusedElement instanceof HTMLInputElement && focusedElement.type === 'checkbox';
  //   const isInput = focusedElement instanceof HTMLInputElement && focusedElement.type !== 'checkbox';
  //   const isSelect = focusedElement instanceof HTMLSelectElement;

  //   // Si es un checkbox, input o select, evita que la tecla "Tab" tenga efecto
  //   if (isCheckbox || isInput || isSelect) {
  //     event.preventDefault();
  //   }
  // }

  ngOnChanges(): void {
    this.tableMaster;
    this.tableFooter;
  }

  generateUniqueId(col: any, cell: any): string {
    return `${col}-${cell}-${Date.now()}`;
  }

  getColumnDefs() {
    return this.tableMaster?.columns.map((column: { columnDef: any; }) => column.columnDef);
  }

  getColumnDefsFooter() {
    return this.tableFooter?.columns.map((column: { columnDef: any; }) => column.columnDef);
  }

  getColumnTypeFooter(column: any): string {
    return column.type;
  }

  getColumnStyles(column: any): { [key: string]: string } {
    const styles: { [key: string]: string } = {};
    if (column.width)
      styles['width'] = column.width;
    if (column.pointerEvents)
      styles['pointer-events'] = column.pointerEvents;
    return styles;
  }

  getCellStyles(column: any, cell?: any): { [key: string]: string } {
    const styles: { [key: string]: string } = {};
    if (column.attr === 'rowspan')
      styles['background-color'] = 'white';
    if (column.pointerEvents)
      styles['pointer-events'] = column.pointerEvents;
    return styles;
  }

  isExtendedRow = (index: number) => {
    if (this.extraColumns[0].length > 0)
      return index === this.extraColumns[0][0].atIndex;
    else
      return false;
  };

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'Deselect' : 'Select'} all`;
    }
    const rowIndex = this.dataSourceMaster.data.indexOf(row);
    return `${this.selection.isSelected(row) ? 'Deselect' : 'Select'} row ${rowIndex + 1}`;
  }

  isAllSelected() {    
    return this.dataSourceMaster.data.filter((row: { Seleccionada: any, ClaveCoberturaDependiente: number, ClaveObligatoriaOpcional: number, SumaAsegurada: string }) => {
      return row.ClaveCoberturaDependiente !== 316 && row.ClaveCoberturaDependiente !== 315 && row.ClaveObligatoriaOpcional !== 1 && row.SumaAsegurada !== 'Amparada';
    }).every((filteredRow: { Seleccionada: any }) => filteredRow.Seleccionada);
  }

  masterToggle() {
    this.selection.clear();
    const selectedValue = !this.isAllSelected();
    this.dataSourceMaster.data.forEach((row: { Seleccionada: number, ClaveCoberturaDependiente: number, ClaveObligatoriaOpcional: number, SumaAsegurada: string }) => {
      if (row.ClaveCoberturaDependiente !== 316 && row.ClaveCoberturaDependiente !== 315 && row.ClaveObligatoriaOpcional !== 1 && row.SumaAsegurada !== 'Amparada') {
        row.Seleccionada = selectedValue ? 1 : 0;
        this.selection.select(row);
      }
    });
  }

  toggleCheckbox(event: any, row: any) {
    row.Seleccionada = event.checked ? 1 : 0;
    this.isAllSelected();
  }

  toggleRadioSelection(selectedValue: boolean) {

    this.dataSourceMaster.data.forEach(
      (row:
         {
           Seleccionada: number
           , ClaveCoberturaDependiente: number
           , ClaveObligatoriaOpcional: number
           , SumaAsegurada: string
           , DisableRows: boolean
         }
       ) => {
         if (row.ClaveCoberturaDependiente == 316
           || row.ClaveCoberturaDependiente == 315) 
           {
           row.DisableRows = !selectedValue;
           row.Seleccionada =  0;
           this.selection.select(row);

         }
       });
    
    this.applyDisabledStyle = selectedValue;

    this.onToggleRadioSelection.emit(selectedValue);
  }

  isDefined(value: any): boolean {
    return value !== null && value !== undefined && value !== '';
  }

  getRowSpan(col: any, index: any) {
    return this.spanColumns[index] && this.spanColumns[index][col];
  }

  cacheSpan(key: any, accessor: any) {
    for (let i = 0; i < this.dataSourceMaster.data.length;) {
      let currentValue = accessor(this.dataSourceMaster.data[i]);
      let count = 1;

      for (let j = i + 1; j < this.dataSourceMaster.data.length; j++) {
        if (currentValue != accessor(this.dataSourceMaster.data[j]))
          break;
        count++;
      }

      if (!this.spanColumns[i])
        this.spanColumns[i] = {};

      this.spanColumns[i][key] = count;
      i += count;
    }
  }

  calculateColumnSum(columnName: string) {
    const totalValue = this.dataSourceMaster.data.reduce((acc: number, item: any) => {
      const columnValue = parseFloat(item[columnName] || '0');
      return acc + columnValue;
    }, 0) as any;
    this.tableFooter?.dataSource.forEach((item: { [x: string]: any; }) => {
      item[columnName] = totalValue;
    });
  }
  
  onPercentChange(value: number): any {
    if (value === 0) {
      return '';
    } else {
      return value.toString();
    }
  }

  isNumber(value: any): boolean {
    return !isNaN(value) && isFinite(value);
  }

  //funciones generales
  isDisableRows(row?: any): boolean {

    /*
    if (this.isMetaAhorro) {

      if(this.applyDisabledStyle == true)
      {
        return row.ClaveCoberturaDependiente === 0 || row.ClaveCoberturaDependiente === 129 ||
        row.ClaveCoberturaDependiente === 130 || row.ClaveCoberturaDependiente === 131 ||
        row.ClaveCoberturaDependiente === 132 || row.ClaveCoberturaDependiente === 114 ||
        row.ClaveCoberturaDependiente === 315 || row.ClaveCoberturaDependiente === 314 ||
        row.ClaveCoberturaDependiente === 316 ? false : true;
      }
      else
      {
        return row.ClaveCoberturaDependiente === 0 || row.ClaveCoberturaDependiente === 129 ||
        row.ClaveCoberturaDependiente === 130 || row.ClaveCoberturaDependiente === 131 ||
        row.ClaveCoberturaDependiente === 132 || row.ClaveCoberturaDependiente === 114 ||
        row.ClaveCoberturaDependiente === 314 ? false : true;
      }      
    }
    else {
      return row.ClaveCoberturaDependiente === 316 ||
        row.ClaveCoberturaDependiente === 315 ||
        row.ClaveObligatoriaOpcional === 1
        ? true : false;
    }
    */
   return row.DisableRows;
  };

  public validarSumaAsegurada(cobertura: any): boolean {
    const { SumaAsegurada, SumaMaxima, SumaMinima } = cobertura;
    return SumaAsegurada >= SumaMinima && SumaAsegurada <= SumaMaxima;
  }

  //funciones para coberturas
  chkRowSelectSumaAsegurada(event: MatCheckboxChange,  element: any) {    
    // Desactiva la selección para todas las coberturas DI (DI 1, DI 2, DI 3)
    this.dataSourceMaster.data.forEach(item => {
      if (item.ClaveCoberturaDependiente >= 130 && item.ClaveCoberturaDependiente <= 132) {
        item.Seleccionada = 0;
        item.SumaAsegurada = 0;
        item.PrimaObjetivo = 0;
      }
    });

    if (!event.checked && element.ClaveCoberturaDependiente == 314) {
      this.dataSourceMaster.data.forEach(item => {
        if (item.ClaveCoberturaDependiente >= 315 && item.ClaveCoberturaDependiente <= 316) {
          item.Seleccionada = 0;
          item.SumaAsegurada = 0;
          item.PrimaObjetivo = 0;
        }
      });
    }

    element.Seleccionada = event.checked ? 1 : 0;

    if (element.Seleccionada == 0) {
      element.SumaAsegurada = 0;
      element.PrimaObjetivo = 0;
    }
    
    this.onChkSelectClick.emit(element);
  }

  txtValueSumaAsegurada(event: any, element: any) {
    
    element.SumaAsegurada = parseFloat(event.target.value.replace(/[,$]/g, ''));
    
    if (element.Seleccionada == 1 && !this.validarSumaAsegurada(element)) {
      
      if (element.SumaMinima === element.SumaMaxima) {
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        
        Swal.fire({
          icon: 'warning'
          , title: '¡Advertencia!'
          , text: `La suma asegurada debe ser mínimo de ${element.SumaMinima} y máximo de ${element.SumaMaxima}`
        }).then(() => {
          element.SumaAsegurada = element.SumaMinima;
          event.target.value = formatter.format(element.SumaMinima);
          this.onTtxtValueSumaAseguradaBlur.emit(element);
        });
      }
      else {
        Swal.fire({
          icon: 'warning'
          , title: '¡Advertencia!'
          , text: `La suma asegurada debe ser mínimo de ${element.SumaMinima} y máximo de ${element.SumaMaxima}`
        });
        element.SumaAsegurada = 0;
        event.target.value = "$0.00";
      }
    }
    else
    {
      this.onTtxtValueSumaAseguradaBlur.emit(element);
    }
  }

  //funciones para reservas
  btnRowspanIcon(row: any) {
    this.onBtnRowspanClick.emit(row);
  }

  txtAssignRange(element: any, columnName: string, event: any, startrange: number, endrange: number) {
    const inputValue = parseFloat(event.target.value);
    if (isNaN(inputValue)) {
      event.target.value = '';
      element.PorcentajeAsignado = event.target.value;
      this.onTxtAssignRangeBlur.emit(element);
      return false;
    }
  
    if (inputValue < startrange || inputValue > endrange) {
      event.target.value = '';
      Swal.fire({
          icon: 'info',
          title: 'Informativo!',
          text: `El valor no coincide con los límites establecidos, min: ${startrange}, max: ${endrange}`,
      }).then(() => {
        element.PorcentajeAsignado = event.target.value;
        this.onTxtAssignRangeBlur.emit(element);
      });     
      return false;
    }
  
    // Actualizar el valor total solo si existe la colección y el elemento
    if (this.tableFooter) {
      const totalValue = this.dataSourceMaster.data.reduce((acc: number, item: any) => {
        const columnValue = parseFloat(item[columnName] || '0');
        return acc + columnValue;
      }, 0);

      this.tableFooter.dataSource.forEach((item: { [x: string]: any }) => {
        item[columnName] = totalValue;
      });
    }
  
    this.onTxtAssignRangeBlur.emit(element);
  
    // Formatear el valor con % y asignarlo al input
    event.target.value = `${inputValue}%`;
    return true;
  }
  
  chkRowSelect(row: any) {
    this.onChkSelectClick.emit(row);
  }

  btnDeleteRow(element: any) {
    this.onBtnDeleteRowClick.emit(element);
  }

  txtTextElement(element: any) {
    this.onTxtTextBlur.emit(element);
  }

  updateValue(value: string, element: any, column: any) {
    let val = parseInt(value, 10);
    if (Number.isNaN(val)) {
      val = 0;
    }
    element[column.columnDef] = val;
  }
}