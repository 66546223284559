import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import Swal, { SweetAlertResult } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {
  // Mockup
  notificaciones = { codigo: '00', mensaje: 'Exitoso', tipo: 'todas', total: 20, pagina: 1, porPagina: 10, data: this.generarNotificaciones() };
  subject = new BehaviorSubject(this.notificaciones.data);
  conteo = new BehaviorSubject(this.notificaciones.data.filter(notificacion => notificacion.pendiente).length);

  constructor() { }

  getNotifications(page: number) {
    return this.generarNotificaciones();
  }

  // Función solo para pruebas (Generador de notificaciones falsas)
  generarNotificaciones(): any[] {
    const notificaciones = [];

    for (let x = 0; x < 15; x++) {
      const randomId = Math.floor(Math.random() * 1000000) + 1;
      const randomOthers = Math.floor(Math.random() * 3);

      // tslint:disable-next-line: max-line-length
      notificaciones.push({ id: randomId, descripcion: `La póliza DO${randomId} está pendiente`, fecha: '10/08/19 10:53 am', pendiente: (randomOthers - 1 === 1) ? true : false, ruta: '/cotizaciones/autos/lala', tipo: randomOthers });
    }

    return notificaciones;
  }

  confirmaEleccionBB(tipo?: string, configTxts?): Promise<SweetAlertResult> {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary py-2 px-4',
        cancelButton: 'mr-2 btn btn-danger py-2 px-4'
      },
      buttonsStyling: false
    });

    return swalWithBootstrapButtons.fire({
      title: configTxts ? configTxts.title : `¿Estás seguro que deseas eliminar ${tipo}?`,
      text: configTxts ? configTxts.text : "Esta acción no es reversible",
      icon: configTxts ? configTxts.icon : 'warning',
      confirmButtonText: configTxts ? configTxts.confirmButtonText : 'Si, eliminar!',
      cancelButtonText: configTxts ? configTxts.cancelButtonText : 'No, cancelar!',
      showCancelButton:  configTxts ? configTxts?.showCancelButton : true,
      reverseButtons: true,
      confirmButtonColor : '#007bff'
    })
  }

  confirmaEleccion(tipo?: string, configTxts?): Promise<SweetAlertResult> {
    return Swal.fire({
      title: configTxts ? configTxts.title : `¿Estás seguro que deseas eliminar ${tipo}?`,
      text: configTxts ? configTxts.text : "Esta acción no es reversible",
      icon: configTxts ? configTxts.icon : 'warning',
      confirmButtonText: configTxts ? configTxts.confirmButtonText : 'Si, eliminar!',
      cancelButtonText: configTxts ? configTxts.cancelButtonText : 'No, cancelar!',
      showCancelButton: configTxts ? configTxts?.showCancelButton : true,
      reverseButtons: true,
      confirmButtonColor : '#007bff'
    })
  }

  addSwalStyle = (primaryColor: string) => {
    let existingStyle = document.getElementById('swal-custom-style');
    if (existingStyle) {
      existingStyle.remove();
    }

    const swalStyle = document.createElement('style');
    swalStyle.id = 'swal-custom-style';
    swalStyle.textContent = `
      .swal2-icon.swal2-info.swal2-icon-show {
        border-color:${primaryColor};
        color: ${primaryColor};
      }
      .swal2-confirm.swal2-styled {
          background-color: ${primaryColor} !important;
      }
    `;
    document.head.appendChild(swalStyle);
  };

  removeSwalStyle = () => {
    const existingStyle = document.getElementById('swal-custom-style');
    if (existingStyle) {
      existingStyle.remove();
    }
  };

}
