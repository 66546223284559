import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appRefreshComponent]'
})
export class RefreshComponentDirective implements OnChanges {

  @Input() appRefreshComponent: any;
  constructor( 
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef
    ) {
      this.viewContainerRef.createEmbeddedView( templateRef );
     }
  ngOnChanges(changes: SimpleChanges): void {
    this.viewContainerRef.clear();
    this.viewContainerRef.createEmbeddedView( this.templateRef );
  }

}
