import { Directive, ElementRef, HostListener, OnInit, Input } from '@angular/core';

@Directive({
  selector: '[OnlyCurrencyFormat]'
})
export class OnlyCurrencyFormatDirective implements OnInit {

  private readonly currencySymbol = '$';

  // Puedes usar un Input para recibir el valor inicial desde el componente
  @Input() initialValue!: string;

  constructor(private el: ElementRef) { }

  ngOnInit(): void {
    // Formatear el valor inicial si está presente
    if (this.initialValue) {
      this.formatAndSetCurrency(this.initialValue);
    }
  }

  @HostListener('focus', ['$event'])
  onFocus(event: Event) {
    const input = event.target as HTMLInputElement;
    input.value = this.extractNumericValue(input.value);
    setTimeout(() => {
      input.setSelectionRange(0, 0);
    }, 50);
  }

  @HostListener('blur', ['$event'])
  onBlur(event: Event) {
    const input = event.target as HTMLInputElement;
    const numericValue = this.extractNumericValue(input.value);
    this.formatAndSetCurrency(numericValue);
  }

  private extractNumericValue(value: string): string {
    return value.replace(/[^0-9.]/g, '');
  }

  private formatAndSetCurrency(value: string): void {
    const numericValue = parseFloat(value);

    if (!isNaN(numericValue)) {
      const formattedValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      }).format(numericValue);

      // Establecer el valor formateado en la caja de texto
      this.el.nativeElement.value = formattedValue;
    }
  }
}